<template>
  <form @submit.prevent="generateInvoice">
    <div>
      <div class="card mb-4">
        <h3 class="subtitle">Carrier Information</h3>
        <div class="lg:flex gap-3">
          <div class="lg:w-6/12">
            <div
              class="form-group"
              :class="{ error: v$.CarrierName.$errors.length }"
            >
              <label>Name <span class="required-star">*</span></label>
              <input
                type="text"
                class="form-control"
                v-model="v$.CarrierName.$model"
              />
              <div
                class="input-errors"
                v-for="(error, index) of v$.CarrierName.$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="lg:w-6/12">
            <div
              class="form-group"
              :class="{ error: v$.CarrierAddress.$errors.length }"
            >
              <label>Address <span class="required-star">*</span></label>
              <input
                type="text"
                class="form-control"
                v-model="v$.CarrierAddress.$model"
              />
              <div
                class="input-errors"
                v-for="(error, index) of v$.CarrierAddress.$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="lg:flex gap-3">
          <div class="lg:w-6/12">
            <div
              class="form-group"
              :class="{ error: v$.CarrierCity.$errors.length }"
            >
              <label>City <span class="required-star">*</span></label>
              <input
                type="text"
                class="form-control"
                v-model="v$.CarrierCity.$model"
              />
              <div
                class="input-errors"
                v-for="(error, index) of v$.CarrierCity.$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="lg:w-6/12">
            <div
              class="form-group"
              :class="{ error: v$.CarrierState.$errors.length }"
            >
              <label>State <span class="required-star">*</span></label>
              <select
                id="txtDestinationState"
                class="custom-select"
                v-model="v$.CarrierState.$model"
              >
                <option value="" selected="selected">Select a state</option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
                <option value="AB">Alberta</option>
                <option value="BC">British Columbia</option>
                <option value="MB">Manitoba</option>
                <option value="NB">New Brunswick</option>
                <option value="NL">Newfoundland and Labrador</option>
                <option value="NS">Nova Scotia</option>
                <option value="ON">Ontario</option>
                <option value="PE">Prince Edward Island</option>
                <option value="QC">Quebec</option>
                <option value="SK">Saskatchewan</option>
                <option value="NT">Northwest Territories</option>
                <option value="NU">Nunavut</option>
                <option value="YT">Yukon</option>
              </select>
              <div
                class="input-errors"
                v-for="(error, index) of v$.CarrierState.$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="lg:flex gap-3">
          <div class="lg:w-6/12">
            <div
              class="form-group"
              :class="{ error: v$.CarrierPostlCode.$errors.length }"
            >
              <label>Postal Code <span class="required-star">*</span></label>
              <input
                type="text"
                class="form-control"
                v-model="v$.CarrierPostlCode.$model"
              />
              <div
                class="input-errors"
                v-for="(error, index) of v$.CarrierPostlCode.$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="lg:w-6/12">
            <div
              class="form-group"
              :class="{ error: v$.CarrierPhone.$errors.length }"
            >
              <label>Phone <span class="required-star">*</span></label>
              <input
                type="text"
                class="form-control"
                @input="CarrierPhoneNumber()"
                v-model="v$.CarrierPhone.$model"
              />
              <div
                class="input-errors"
                v-for="(error, index) of v$.CarrierPhone.$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="lg:flex gap-3">
          <div class="lg:w-6/12">
            <div
              class="form-group"
              :class="{ error: v$.CarrierEmail.$errors.length }"
            >
              <label>Email <span class="required-star">*</span></label>
              <input
                type="text"
                class="form-control"
                v-model="v$.CarrierEmail.$model"
              />
              <div
                class="input-errors"
                v-for="(error, index) of v$.CarrierEmail.$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="lg:w-6/12">
            <div class="form-group">
              <label>Invoice # </label>
              <input type="text" class="form-control" v-model="invoiceNumber" />
            </div>
          </div>
        </div>
        <div class="flex items-center mb-4">
          <input type="checkbox" class="mr-1" v-model="checked" />
          <label>Use a Factoring Company</label>
        </div>
      </div>
      <div class="card" v-if="checked">
        <h3 class="subtitle">Remit to Information</h3>
        <p>
          Select a pre-built Factoring Company below, if yours is not listed,
          select 'other' and enter the information manually.
        </p>
        <div class="lg:flex gap-3">
          <div class="lg:w-6/12">
            <div
              class="form-group"
              :class="{ error: v$.RemitFactoringCompany.$errors.length }"
            >
              <label class="flex"
                >Factoring Company <span class="required-star">*</span></label
              >
              <Dropdown
                v-model="v$.RemitFactoringCompany.$model"
                :options="FacotringComapnies"
                optionLabel="name"
                optionValue="value"
                placeholder="-- Select Factoring Company --"
                @change="SelectedFactComp"
                class="flex"
              />
              <div
                class="input-errors"
                v-for="(error, index) of v$.RemitFactoringCompany.$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="lg:w-6/12">
            <div
              class="form-group"
              :class="{ error: v$.RemitName.$errors.length }"
            >
              <label>Name <span class="required-star">*</span></label>
              <input
                type="text"
                class="form-control"
                :disabled="isDisable"
                v-model="v$.RemitName.$model"
              />
              <div
                class="input-errors"
                v-for="(error, index) of v$.RemitName.$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="lg:flex gap-3">
          <div class="lg:w-6/12">
            <div
              class="form-group"
              :class="{ error: v$.RemitAddress.$errors.length }"
            >
              <label>Address <span class="required-star">*</span></label>
              <input
                type="text"
                class="form-control"
                :disabled="isDisable"
                v-model="v$.RemitAddress.$model"
              />
              <div
                class="input-errors"
                v-for="(error, index) of v$.RemitAddress.$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="lg:w-6/12">
            <div
              class="form-group"
              :class="{ error: v$.RemitCity.$errors.length }"
            >
              <label>City <span class="required-star">*</span></label>
              <input
                type="text"
                class="form-control"
                :disabled="isDisable"
                v-model="v$.RemitCity.$model"
              />
              <div
                class="input-errors"
                v-for="(error, index) of v$.RemitCity.$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="lg:flex gap-3">
          <div class="lg:w-6/12">
            <div
              class="form-group"
              :class="{ error: v$.RemitState.$errors.length }"
            >
              <label>State <span class="required-star">*</span></label>
              <select
                id="txtDestinationState"
                class="custom-select"
                v-model="v$.RemitState.$model"
                :disabled="true"
              >
                <option value="" selected="selected">Select a state</option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
                <option value="AB">Alberta</option>
                <option value="BC">British Columbia</option>
                <option value="MB">Manitoba</option>
                <option value="NB">New Brunswick</option>
                <option value="NL">Newfoundland and Labrador</option>
                <option value="NS">Nova Scotia</option>
                <option value="ON">Ontario</option>
                <option value="PE">Prince Edward Island</option>
                <option value="QC">Quebec</option>
                <option value="SK">Saskatchewan</option>
                <option value="NT">Northwest Territories</option>
                <option value="NU">Nunavut</option>
                <option value="YT">Yukon</option>
              </select>
              <div
                class="input-errors"
                v-for="(error, index) of v$.RemitState.$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="lg:w-6/12">
            <div
              class="form-group"
              :class="{ error: v$.RemitPostalCode.$errors.length }"
            >
              <label>Postal Code <span class="required-star">*</span></label>
              <input
                type="text"
                class="form-control"
                v-model="v$.RemitPostalCode.$model"
                :disabled="isDisable"
              />
              <div
                class="input-errors"
                v-for="(error, index) of v$.RemitPostalCode.$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="lg:flex gap-3">
          <div class="lg:w-6/12">
            <div
              class="form-group"
              :class="{ error: v$.RemitPhone.$errors.length }"
            >
              <label>Phone <span class="required-star">*</span></label>
              <input
                type="text"
                :disabled="isDisable"
                class="form-control"
                @input="RemitPhoneNumber()"
                v-model="v$.RemitPhone.$model"
              />
              <div
                class="input-errors"
                v-for="(error, index) of v$.RemitPhone.$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="lg:w-6/12">
            <div
              class="form-group"
              :class="{ error: v$.RemitEmail.$errors.length }"
            >
              <label>Email <span class="required-star">*</span> </label>
              <input
                type="text"
                class="form-control"
                v-model="v$.RemitEmail.$model"
              />
              <div
                class="input-errors"
                v-for="(error, index) of v$.RemitEmail.$errors"
                :key="index"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button class="btn block mb-3" @click="add" type="button">
        + Add Fee
      </button>
      <div v-for="(item, index) in RowData" :key="index">
        <div class="lg:flex gap-3">
          <div class="lg:w-5/12">
            <div
              class="form-group"
              :class="{
                error: v$.RowData.$each.$response.$errors[index].Description,
              }"
            >
              <label>Type <span class="required-star">*</span> </label>
              <div class="flex">
                <Dropdown
                  v-model="item.Description"
                  :options="FeesDescription"
                  optionLabel="name"
                  optionValue="value"
                  placeholder="-- Select Description --"
                />
              </div>
              <div
                class="input-errors"
                v-for="error in v$.RowData.$each.$response.$errors[index]
                  .Description"
                :key="error"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="lg:w-5/12">
            <div class="form-group">
              <label>Amount <span class="required-star">*</span> </label>
              <input type="text" class="form-control" v-model="item.Amount" />
              <div
                class="input-errors"
                v-for="error in v$.RowData.$each.$response.$errors[index]
                  .Amount"
                :key="error"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="lg:w-1/12">
            <div class="form-group">
              <button
                type="button"
                class="form-control mt-5"
                @click="removeItem(index)"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      <button class="main-btn" type="submit">Generate invoice</button>
    </div>
  </form>
  <div class="success-msg" v-if="isSuccessfull">{{ responseText }}</div>
  <div class="err-msg" v-if="!isSuccessfull && isException">
    {{ responseText }}
  </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import {
  email,
  required,
  helpers,
  requiredIf,
  integer,
} from "@vuelidate/validators";
import { dataProvider } from "@/api/dataProvider";
import Dropdown from "primevue/dropdown";
export default {
  components: {
    Dropdown,
  },
  data() {
    return {
      isDisable: false,
      v$: useVuelidate(),
      Amount: null,
      Description: "",
      RowData: [],
      FeesDescription: [
        { name: "Lumper", value: "LUMPER" },
        { name: "Chassis", value: "CHASSIS" },
        { name: "Detention", value: "DETENTION" },
        { name: "Other", value: "OTHER" },
      ],
      test: "",
      FacotringComapnies: [
        { name: "Apex Capital Corp.", value: "0" },
        { name: "RTS Financial Service", value: "1" },
        { name: "Advance Business Capital", value: "2" },
        { name: "D&S Factors LLC", value: "3" },
        { name: "TBS Factoring Service LLC", value: "4" },
        { name: "Other", value: "5" },
      ],
      RemitFactoringCompanies: [],
      CarrierName: this.$store.state.login.CompanyName,
      CarrierAddress: this.$store.state.login.AddressLine1,
      CarrierCity: this.$store.state.login.City,
      CarrierState: this.$store.state.login.State,
      CarrierPostlCode: this.$store.state.login.ZipCode,
      CarrierPhone: this.$store.state.login.PhoneNumber,
      CarrierEmail: this.$store.state.login.EmailAddress,
      CarrierCareOf: "",
      invoiceNumber: "",
      RemitFactoringCompany: "",
      RemitName: "",
      RemitAddress: "",
      RemitCity: "",
      RemitState: "",
      RemitPostalCode: "",
      RemitPhone: "",
      RemitEmail: "",
      Invoice: [
        {
          Carrier: {
            Name: "",
            AddressLine1: "",
            City: "",
            State: "",
            PostalCode: "",
            Phone: "",
            Email: "",
            CareOf: "",
          },
          BillTo: {
            Name: "",
            AddressLine1: "",
            City: "",
            State: "",
            PostalCode: "",
            CareOf: "",
            Phone: "",
            Email: "",
          },
          RemitTo: {
            CareOf: "",
            Name: "",
            AddressLine1: "",
            City: "",
            State: "",
            PostalCode: "",
            Phone: "",
            Email: "",
          },
          LoadID: 0,
          InvoiceDate: new Date(),
          LineItems: [
            {
              Description: "",
              Amount: 0,
            },
          ],
          TotalAmount: 0,
          UseFactoringCompany: true,
          InvoiceNumber: "",
        },
      ],
      InvoiceData: [],
      InvoiceDataToPDF: [],
      checked: false,
      responseText: "",
      isSuccessfull: false,
      isException: false,
    };
  },
  created() {
    this.getFactoringCompanies();
  },
  methods: {
    async getFactoringCompanies() {
      this.RemitFactoringCompanies = await dataProvider.getFactoringCompanies();
      this.RemitFactoringCompanies = JSON.parse(this.RemitFactoringCompanies);
    },
    SelectedFactComp() {
      (this.RemitName =
        this.RemitFactoringCompanies[this.RemitFactoringCompany].name),
        (this.RemitAddress =
          this.RemitFactoringCompanies[this.RemitFactoringCompany].address),
        (this.RemitCity =
          this.RemitFactoringCompanies[this.RemitFactoringCompany].city),
        (this.RemitState =
          this.RemitFactoringCompanies[this.RemitFactoringCompany].state),
        (this.RemitPostalCode =
          this.RemitFactoringCompanies[this.RemitFactoringCompany].postalCode),
        (this.RemitPhone =
          this.RemitFactoringCompanies[this.RemitFactoringCompany].phone),
        (this.RemitEmail =
          this.RemitFactoringCompanies[this.RemitFactoringCompany].email);
      if (this.RemitName == "Other") {
        this.isDisable = false;
      } else {
        this.isDisable = true;
      }
    },
    add() {
      var my_object = {
        Description: this.Description,
        Amount: this.Amount,
      };
      this.RowData.push(my_object);
    },
    removeItem(index) {
      this.RowData.splice(index, 1);
    },
    RemitPhoneNumber() {
      var x = this.RemitPhone.replace(/\D/g, "").match(
        /(\d{0,3})(\d{0,3})(\d{0,4})/
      );
      this.RemitPhone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    CarrierPhoneNumber() {
      var x = this.CarrierPhone.replace(/\D/g, "").match(
        /(\d{0,3})(\d{0,3})(\d{0,4})/
      );
      this.CarrierPhone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    async generateInvoice() {
      this.v$.$touch();
      if (this.v$.$error) return;

      (this.Invoice[0].Carrier.Name = this.CarrierName),
        (this.Invoice[0].Carrier.AddressLine1 = this.CarrierAddress),
        (this.Invoice[0].Carrier.City = this.CarrierCity),
        (this.Invoice[0].Carrier.State = this.CarrierState),
        (this.Invoice[0].Carrier.PostalCode = this.CarrierPostlCode),
        (this.Invoice[0].Carrier.Phone = this.CarrierPhone),
        (this.Invoice[0].Carrier.Email = this.CarrierEmail),
        (this.Invoice[0].Carrier.CareOf = this.CarrierCareOf),
        (this.Invoice[0].RemitTo.CareOf = this.RemitFactoringCompany),
        (this.Invoice[0].RemitTo.Name = this.RemitName),
        (this.Invoice[0].RemitTo.AddressLine1 = this.RemitAddress),
        (this.Invoice[0].RemitTo.City = this.RemitCity),
        (this.Invoice[0].RemitTo.State = this.RemitState),
        (this.Invoice[0].RemitTo.PostalCode = this.RemitPostalCode),
        (this.Invoice[0].RemitTo.Email = this.RemitEmail),
        (this.Invoice[0].RemitTo.Phone = this.RemitPhone),
        (this.Invoice[0].LoadID = this.$store.state.upload.LoadID),
        (this.Invoice[0].LineItems = this.RowData);
      this.Invoice[0].InvoiceNumber = this.invoiceNumber;
      this.Invoice[0].UseFactoringCompany = this.checked;
      this.InvoiceData.push(this.Invoice[0]);

      var GenerateInvoice = await dataProvider.generateInvoice(
        this.InvoiceData
      );
      this.isSuccessfull = GenerateInvoice.IsSuccessful;
      this.isException = GenerateInvoice.IsException;
      this.responseText = GenerateInvoice.ResponseText;
      setTimeout(
        () => ((this.isSuccessfull = false), (this.isException = false)),
        5000
      );
      this.InvoiceDataToPDF = GenerateInvoice.invoiceData;

      this.$emit("InvoiceData", this.InvoiceDataToPDF, this.isSuccessfull);
    },
  },
  validations() {
    return {
      CarrierName: {
        required: helpers.withMessage("Name is required", required),
      },
      CarrierAddress: {
        required: helpers.withMessage("Address is required", required),
      },
      CarrierCity: {
        required: helpers.withMessage("City is required", required),
      },
      CarrierState: {
        required: helpers.withMessage("State is required", required),
      },
      CarrierPostlCode: {
        required: helpers.withMessage("Postal Code is required", required),
      },
      CarrierPhone: {
        required: helpers.withMessage("Phone is required", required),
      },
      CarrierEmail: {
        required: helpers.withMessage("Email is required", required),
        email: helpers.withMessage("Please enter a valid email", email),
      },
      RemitName: {
        required: helpers.withMessage(
          "Name is required",
          requiredIf(this.checked)
        ),
      },
      RemitAddress: {
        required: helpers.withMessage(
          "Address is required",
          requiredIf(this.checked)
        ),
      },
      RemitCity: {
        required: helpers.withMessage(
          "City is required",
          requiredIf(this.checked)
        ),
      },
      RemitState: {
        required: helpers.withMessage(
          "State is required",
          requiredIf(this.checked)
        ),
      },
      RemitPhone: {
        required: helpers.withMessage(
          "Phone is required",
          requiredIf(this.checked)
        ),
      },
      RemitPostalCode: {
        required: helpers.withMessage(
          "Postal Code is required",
          requiredIf(this.checked)
        ),
      },
      RemitEmail: {
        required: helpers.withMessage(
          "Email is required",
          requiredIf(this.checked)
        ),
        email: helpers.withMessage("Please enter a valid email", email),
      },
      RemitFactoringCompany: {
        required: helpers.withMessage(
          "Factoring Comapny is required",
          requiredIf(this.checked)
        ),
      },
      RowData: {
        $each: helpers.forEach({
          Amount: {
            required: helpers.withMessage("Amount is required", required),
            integer: helpers.withMessage("Amount should be a number", integer),
          },
          Description: {
            required: helpers.withMessage("Description is required", required),
          },
        }),
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.success-msg {
  background: #5ad568ee;
  color: #fff;
  padding: 10px 20px;
  text-align: center;
  border-radius: 6px;
  margin-top: 10px;
}
.err-msg {
  background: #ce1414ee;
  color: #fff;
  padding: 10px 20px;
  text-align: center;
  border-radius: 6px;
  margin-top: 10px;
}
.p-dropdown {
  width: 100%;
}
</style>
