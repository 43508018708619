<template>
  <p class="mb-4">
    To verify your identity, LDi needs the LDi Load# and either your MC# OR USDOT# for the load you want to view:
  </p>
  <form action="#" @submit.prevent="doUpload" class="form">
    <div class="form-group" :class="{ error: v$.form.LoadID.$errors.length }">
      <label>LDi Load#: <span class="required-star">*</span></label>
      <input type="text" class="form-control" v-model="v$.form.LoadID.$model" />
      <div
        class="input-errors"
        v-for="(error, index) of v$.form.LoadID.$errors"
        :key="index"
      >
        <div class="error-msg">{{ error.$message }}</div>
      </div>
    </div>
    <div
      class="form-group"
      :class="{ error: v$.form.MCUSNumber.$errors.length }"
    >
      <label>MC/USDOT Number:<span class="required-star">*</span></label>
      <input
        type="text"
        class="form-control"
        v-model="v$.form.MCUSNumber.$model"
      />
      <div
        class="input-errors"
        v-for="(error, index) of v$.form.MCUSNumber.$errors"
        :key="index"
      >
        <div class="error-msg">{{ error.$message }}</div>
      </div>
    </div>

    <div class="text-center">
      <button class="main-btn">Verify</button>
    </div>
  </form>
  <div class="success-msg" v-if="isSuccessfull">{{ responseText }}</div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, helpers, integer } from "@vuelidate/validators";
export default {
  data() {
    return {
      state: false,
      isSuccessfull: false,
      responseText: "",
      v$: useVuelidate(),
      selected: "",
      form: {
        CarrierID: this.$store.state.login.CarrierID,
        CarrierPortalUserID: this.$store.state.login.CarrierPortalUserID,
        EmailAddress: this.$store.state.login.EmailAddress,
        LoadID: "",
        MCUSNumber: "",
      },
    };
  },
  validations() {
    return {
      form: {
        LoadID: {
          required: helpers.withMessage("Load id is required", required),
          integer: helpers.withMessage("Load id should be a number", integer),
        },
        MCUSNumber: {
          required: helpers.withMessage(
            "MC/USDOT number is required",
            required
          ),
        },
      },
    };
  },
  methods: {
    async doUpload() {
      this.v$.form.$touch();

      if (this.v$.form.$error) return;

      try {
        // Send login action to store
        var response = await this.TryDoUpload(this.form);
        this.message = response.ResponseText;
        this.IsSuccessful = response.IsSuccessful;
        this.$emit("McNumb", this.form.MCUSNumber);
        this.$emit("closeModal", response);
      } catch (err) {
        if (err.status === 403) {
          this.combinemessage = err.combinemessage;
          this.$emit("closeModal", response);
        }
      }
    },
    ...mapActions("upload", ["TryDoUpload"]),
  },
  computed: {
    ...mapGetters({
      initialLoginType: "environment/LoginType",
      apiKey: "environment/APIUrl",
      dataFetched: "environment/Fetched",
    }),
  },

  mounted() {
    document.addEventListener("click", this.close);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.close);
  },
};
</script>
<style lang="scss" scoped>
.right-column {
  .form-group {
    margin-bottom: 20px;
    &.error {
      .form-control {
        border: 1px solid #ff073a;
        margin-bottom: 5px;
      }
      .error-msg {
        color: #ff073a;
      }
    }
  }
}
.success-msg {
  background: #5ad568ee;
  color: #fff;
  padding: 10px 20px;
  text-align: center;
  border-radius: 6px;
  margin-top: 10px;
}
.form-group {
  margin-bottom: 20px;
  &.error {
    .form-control {
      border: 1px solid #ff073a;
    }
  }
}
.form-control {
  margin-bottom: 5px;
}
.input-errors {
  font-size: 14px;
  color: #ff073a;
}
</style>
