<template>
  <div v-if="!uploadResponse">
    <div class="container px-3 lg:p-0">
      <h3 class="enter-msg mb-3">
        Please refresh page to upload documents
      </h3>
      <button class="main-btn" @click="reload">Reload Page</button>
    </div>
  </div>
  <div v-if="uploadResponse">
    <div v-if="!uploadResponse.IsSuccessful">
      <h3 class="e-msg">
        {{ uploadResponse.ResponseText }}
      </h3>
      <button class="main-btn" @click="reload">Reload Page</button>
    </div>
  </div>
  <div
    v-if="
      uploadResponse && uploadResponse.Locations && uploadResponse.PaymentInfo
    "
  >
    <div class="container px-3 lg:p-0">
      <h3 class="page-title">Load Details</h3>
      <LoadDetails></LoadDetails>
      <CheckNumber></CheckNumber>
      <div class="text-center">
        <button class="main-btn" @click="openContact">Questions?</button>
      </div>
      <h3 class="subtitle">Email a document to LDI</h3>
      <p>For your payment processed immediately LDI requires</p>
      <ul class="custom-list">
        <li>Your Invoice</li>
        <li>Signed rate confirmation</li>
        <li>Bill of landing proof of delivery</li>
      </ul>
      <FileUpload
        :IData="IData"
        :DocMcNumber="DocMcNumber"
        :status="status"
        :submitStatus="submitStatus"
      ></FileUpload>
    </div>

    <ContactDialogue ref="contactDialogue"></ContactDialogue>
  </div>

  <ContactDialogue ref="contactDialogue"></ContactDialogue>
  <UploadDialogue
    ref="saveConfirmDialogue"
    @updateData="updateData"
    @Mcnumber="Mcnumber"
  ></UploadDialogue>
</template>

<script>
import UploadDialogue from "@/components/common/UploadDialogue.vue";
import FileUpload from "@/components/common/FileUpload.vue";
import ContactDialogue from "@/components/common/ContactDialogue.vue";
import LoadDetails from "@/components/common/LoadDetails.vue";
import CheckNumber from "@/components/common/CheckNumber.vue";

export default {
  data() {
    return {
      loadId: "",
      pickupLocation: "",
      paymentStatus: "",
      paymentDate: "",
      Amount: "",
      checkNumber: "",
      locations: [],
      IData: [],

      status: "",
      submitStatus: "",
      IsSuccessful: false,
      DocMcNumber: "",
    };
  },
  components: {
    UploadDialogue,
    FileUpload,
    ContactDialogue,
    LoadDetails,
    CheckNumber,
  },
  beforeMount() {},
  mounted() {
    this.$refs.saveConfirmDialogue.show();
  },
  computed: {
    uploadResponse() {
      return this.$store.state.upload.response;
    },
  },
  methods: {
    InvoiceData(iData, flag) {
      this.IData = iData;
      this.submitStatus = flag;
    },
    openContact() {
      this.$refs.contactDialogue.show();
    },
    updateData(value) {
      this.pickupLocation = value.ResponseText;
      this.loadId = value.PaymentInfo[0].LoadID;
      this.paymentStatus = value.PaymentInfo[0].PaymentStatus;
      this.paymentDate = value.PaymentInfo[0].PaymentDate;
      this.Amount = value.PaymentInfo[0].Amount;
      this.checkNumber = value.PaymentInfo[0].CheckNumber;
      this.locations = value.Locations;
    },
    updateStatus(value) {
      this.status = value;
    },
    Mcnumber(value) {
      this.DocMcNumber = value;
    },
    reload() {
      this.$refs.saveConfirmDialogue.show();
    },
  },
};
</script>
<style lang="scss">
.subtitle {
  font-size: 22px;
  font-weight: 700;
  font-family: "montserrat", sans-serif;
  color: #181818;
  margin-bottom: 10px;
}
.table {
  width: 100%;
  vertical-align: top;
  border-color: #dee2e6;
  td {
    border: 1px solid #f2f2f2;
    padding: 8px;
  }
}
.custom-list {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
  li {
    &:before {
      content: "\2713";

      display: inline-block;
      margin-right: 5px;
    }
  }
}
.invoice-title {
  font-size: 30px;
  font-weight: 700;
  color: #181818;
}
.enter-msg {
  // text-align: center;
  font-size: 36px;
  font-weight: 700;
}
.e-msg {
  text-align: left;
  font-size: 30px;
  font-weight: 700;
}
</style>
